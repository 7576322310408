<template>
  <div class="">
    <div class="register_tip_box">
      <div class="register_tip_img">
        <p class="register_text">免费领取30天高级会员，价值199元</p>
        <p class="register_text1">免费领取30天高级会员</p>
        <p class="register_text1">价值199元</p>
        <a
          href="https://v2.yunzhanggui.net/user/register"
          class="register_button"
          target="_blank"
          >立即注册</a
        >
      </div>
    </div>
    <div class="liner_gradient_box"></div>
    <div class="website-bottom">
      <div class="desc_box">
        <div class="yzg_logo_columns">
          <i class="yzg-logo"></i>
          <div class="ally_box">
            <div class="relative hover_act_box">
              <i class="icon_wx"></i>
              <div class="icon_act_wx">
                <i class="icon_act_wx"></i>
              </div>
              <div class="hover_wx_code_box">
                <p class="tc yzg_code_text">微信公众号</p>
                <p class="tc init_ft_12">请扫描二维码</p>
                <div class="f-r-c-c">
                  <img
                    src="../assets/pic/yzg_qr_code.png"
                    alt=""
                    style="width: 96px; margin-top: 12px"
                  />
                </div>
              </div>
            </div>
            <div class="hover_weibo">
              <a href="https://weibo.com/u/5136886253?is_all=1" class="icon_weibo" target="_blank"></a>
              <div class="hover_weibo_box">
                <a href="https://weibo.com/u/5136886253?is_all=1" class="icon_act_weibo" target="_blank"></a>
              </div>
            </div>
            <div class="hover_zhihu">
              <a href="https://www.zhihu.com/people/yun-zhang-ju-83" class="icon_zhihu" target="_blank"></a>
              <div class="hover_zhihu_box">
                <a href="https://www.zhihu.com/people/yun-zhang-ju-83" class="icon_act_zhihu" target="_blank"></a>
              </div>
            </div>
          </div>
          <div class="yzg_cooperation">
            <span>lzkj-marketing@ly.com （业务合作）</span>
          </div>
          <div class="yzg_cooperation mt18">
            <span>咨询电话：0512-83621081</span>
            <span>服务时间：9:00-21:00</span>
          </div>
        </div>
        <div class="yzg_product_box">
          <span class="product_title">产品</span>
          <ul class="product_list">
            <li class="item hover_a_box" @click="handleRouter('instructions')">功能介绍</li>
            <li class="item hover_a_box" @click="handleRouter('instructions')">解决方案</li>
            <li class="item hover_a_box" @click="handleRouter('instructions')">生态服务</li>
            <li class="item router_international" @click="handleRouter('360pms')">国际版</li>
          </ul>
        </div>
        <div class="yzg_product_box">
          <span class="product_title">公司</span>
          <ul class="product_list scrollLable">
            <li class="item hover_yzg_info" @click="handleRouter('about',0)">关于我们</li>
            <li class="item hover_yzg_info"  @click="handleRouter('about',1)">最新动态</li>
            <li class="item hover_yzg_info"  @click="handleRouter('about',2)">合作伙伴</li>
            <li class="item hover_yzg_info"  @click="handleRouter('about',3)">加入我们</li>
          </ul>
        </div>
        <div class="yzg_product_box">
          <span class="product_title">支持</span>
          <ul class="product_list">
            <li class="item openPrivacyPop" @click="handlePrivacyShow">隐私条款</li>
            <li class="item openServePop" @click="handleServeShow">服务条款</li>
            <li class="item" style="width: 189px; font-size: 12px" @click="handleRouter('ICP')">
              © 2012-{{ endYear }} 云掌柜住宿业数字化营销管家 沪ICP备17019117号-{{beian}}
            </li>
<!--            <li class="item" style="font-size: 12px"  @click="handleRouter('GHS')">-->
<!--              <i class="icon_ghs"></i>-->
<!--              京公网安备 11010802021599号-->
<!--            </li>-->
          </ul>
        </div>
      </div>
    </div>
    <div class="right_window">
      <div class="icon_wx_big_box">
        <i class="icon_big_wx"></i>
        <div class="hover_big_wx_box">
          <i class="icon_act_big_wx"></i>
          <div class="hover_wx_code_box" style="bottom: 0; left: -150px">
            <p class="tc yzg_code_text">微信公众号</p>
            <p class="tc init_ft_12">请扫描二维码</p>
            <div class="f-r-c-c">
              <img
                src="../assets/pic/yzg_qr_code.png"
                alt=""
                style="width: 96px; margin-top: 12px"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="icon_gift_box" @click="chatClick();">
        <i class="icon_big_gift"></i>
      </div>
    </div>
    <PrivacyPop @closePrivacyPop = closePrivacyPop></PrivacyPop>
    <ServePop @closeServePop = closeServePop></ServePop>
  </div>
</template>

<script>
import PrivacyPop from "../components/PrivacyPop"
import ServePop from "../components/ServePop"
import eventBus from "../eventBus"
export default {
  components: {
    PrivacyPop,
    ServePop
  },
  data() {
    return {
      privacyVisible: false,
      serveVisible: false,
      beian: "",
      endYear: new Date().getFullYear(),
    };
  },
  methods: {
    handlePrivacyShow(){
      this.privacyVisible = true
      eventBus.$emit('privacyShow',this.privacyVisible)
    },
    closePrivacyPop(){
      this.privacyVisible = false
    },
    closeServePop(){
       this.serveVisible = false
    },
    handleServeShow(){
      this.serveVisible = true
      eventBus.$emit('serveShow',this.serveVisible)
    },
    handleRouter(type,num){
      switch (type){
        case '360pms' :
          window.open('http://www.360pms.com/login/','_blank')
          break;
        case 'instructions' :{
          let { href } = this.$router.resolve({
            name: "yzgInstructions",
          });
          window.open(href, '_blank');
          break;
        }
        case 'about':{
          let { href } = this.$router.resolve({
            name: "aboutUs",
            query:{id:num}
          });
          window.open(href, '_blank');
          break;
        }
        case 'ICP':{
          window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank')
          break;
        }
        case "GHS":{
          window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802021599",'_blank')
          break;
        }
      }

    },
    chatClick(){
      window.open("https://www.yiqu-service.com/livechatcust/pc_index.html?tenantId=f80a8fb247f1434ba3071fb213e44be0&sourceType=1&channelCode=c50d2c88bc374540b1e607a43c0467e3&#/", "_blank");
    }
  },
  computed: {},
  props: {},
  watch: {},
  created() {},
  mounted() {
    console.log("window", window.location.href);
    this.beian = window.location.href.includes("yunzhanggui.com") ? 7 : 10;
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
@import url("../assets/index.css");
@import url("../assets/media.css");
//@import url(); 引入公共css类
</style>
